.AdvancedOptionModule {
	background-color: $COLOR_BLUE_LIGHT;
	width: 100vw;
	height: auto;
	color: $COLOR_BLACK;
	position: relative;
	z-index: 1002;


	.innerContainer {
		padding-right: 40px;
		padding-left: 40px;
		padding-top: 49px;
		padding-bottom: 49px;
		max-width: 1200px;
		margin: 0 auto;

		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 140%; /* 16.8px */

		.content {
			display: flex;
			justify-content: space-between;

			@media only screen and (max-width: 1000px) {
				display: block;
			}

			.left {

				.header {
					font-size: 32px;
					font-style: normal;
					font-weight: 900;
					line-height: 100%; /* 32px */
					letter-spacing: -0.64px;
					text-transform: uppercase;
					margin-bottom: 9px;
				}

				max-width: 400px;
				margin-right: 50px;
			}

			.right {
				max-width: 400px;

				.link {
					margin-top: 9px;
					font-weight: 700;
					text-transform: uppercase;
				}

			}

		}

	}
}
