.CardModule.doubleHeight {
	height: 200vh;
}

.CardModule {
	border-top: solid 1px rgba(37, 36, 35, 0.1);
//background-color: black;
	color: $COLOR_BLACK;

	position: relative;
	height: 100vh;

	video {
		position: fixed;
		top:0px;
		left: 0px;
		width: 100%;
	}

	.innerContainer {
		position: fixed;
		height: 100vh;
		top: 0px;
		width: 100vw;
		background-color: $COLOR_SAND;
		//max-width: 1200px;
		margin: auto;

		.content.left {
			margin-left: 50%;
		}

		.content.center {
			text-align: center;
			max-width: 600px;
			margin: auto;
		}

		.content {
			padding-top: 120px;
			margin-left: 40px;
			margin-right: 40px;
			padding-bottom: 100px;

			.header {
				margin-bottom: 100px;

				.ruHeader {
					color: $COLOR_RED;
					font-family: $FONT;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 120%; /* 19.2px */
					letter-spacing: -0.64px;
					margin-bottom: 11px;
				}

				h2 {

					font-family: Work Sans;
					font-size: 24px;
					font-style: normal;
					font-weight: 500;
					line-height: 80%; /* 19.2px */
					letter-spacing: -0.48px;
				}

			}

			.subHeader {
				color: #252423;
				font-family: $FONT_SERIF;
				font-size: 36px;
				font-style: normal;
				font-weight: 400;
				line-height: 110%; /* 39.6px */
				letter-spacing: -1.08px;
				max-width: 572px;
			}

			.body.center {

				margin: 37px auto;

			}

			.body {
				font-family: $FONT_SERIF;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				color: $COLOR_BLACK;
				max-width: 313px;

			}




		}

		.stepsContent {
			text-align: center;
			max-width: 900px;
			margin: auto;
			padding-bottom: 200px;



			.steps {

				display: flex;
				justify-content: space-between;

				.step {

					.svg {

					}

					.stepText {
						max-width: 216px;

						.stepStep {
							margin-top: 60px;
							font-family: $FONT_SERIF;
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 100%; /* 12px */
							text-transform: capitalize;
						}

						.stepHeader {

							margin-top: 11px;
							font-size: 28px;
							font-style: normal;
							font-weight: 600;
							line-height: 100%; /* 28px */
							letter-spacing: -0.56px;
						}
					}
				}

			}
		}
	}

}
