html,
body {
	font-size: 22px;
	font-family: $FONT;

	vertical-align: baseline;
	font-weight: normal;

	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	-webkit-tap-highlight-color: transparent;

	//	text-rendering: auto;
	//	text-rendering: optimizeSpeed;
	//  text-rendering: optimizeLegibility;
	//	text-rendering: geometricPrecision;
	//	text-rendering: auto;
	overscroll-behavior: none;
	overflow-x: hidden;
}

* {
	user-select: none;
}

text {
	font-family: $FONT;
}

body {
	overflow-x: hidden;
	background-color: #1a1a1a; //252423
	color: #FFFBE9;
}

.topArea {
	pointer-events: none;
	height: 300vh;
}

h1 {
	font-family: 'Inter', sans-serif;
	font-size: 120px;
	line-height: 100px;
}

h2 {
	color: $COLOR_BLACK;
	font-family: $FONT_SERIF;
	font-size: 40px;
	font-style: normal;
	font-weight: 400;
	line-height: 80%; /* 32px */
	letter-spacing: -0.8px;
}

#ForegroundVideo {
	//display: none;
	position: fixed;
	top: 0;
	z-index: 100000;
	pointer-events: none;
	opacity: 0.5;
	//	transform: rotate(30deg);
	min-width: 100vw;
	min-height: 100vh;
}

.cardContent {
	pointer-events: none;
}


#VideoReveal {

	background-color: $COLOR_SAND;


}

video {
	position: fixed;
	pointer-events: none;
	z-index: 100;
	top: 0px;
	left: 0px;
	width: 100vw;

	//mix-blend-mode: multiply;
	width: 100vw;
	//image-rendering: crisp-edges;
	//filter: blur(1.5px);

}

.cardPopUp.darker {
	//background-image: url(/assets/images/background_texture2_30.png);
	background-color: #F9F2D4;
}

.cardPopUp.darkest {
	//background-color: #e8deb8;
	background-color: transparent;
	//background-color: #e8deb8;
	background-size: 200vw 412px;
	background-repeat: no-repeat;
	background-image: url(/assets/images/darkest_bg.png);

}

.cardPopUp {
	pointer-events: none;
	//background: url(/assets/images/background_texture1_30.png) no-repeat center center fixed;
	/*-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;*/
	background-position: -400px -200px;


	//	border-top: solid 1px rgba(37, 36, 35, 0.1);
	transform: translateY(150vh);

	opacity: 1;
	//filter: drop-shadow(-17px 2px 10px rgba(0, 0, 0, 0.1));
	position: fixed;
	height: calc(100vh + 300px);
	top: 0px;
	width: 100vw;
	left: 0vw;
	background-color: $COLOR_SAND;
	//max-width: 1200px;
	margin: auto;
	z-index: 10;

	.wall {
		position: relative;
		top: -125px;
		//	width: 1920px;
		margin-left: 0vw;
		pointer-events: none;

		img {
			width: 100vw;
		}
	}

	.crackedWall {
		position: absolute;
		top: 210px;


		//background-color: red;

		left: 0vw;

		img {
			left: 0px;
			position: absolute;
			object-fit: cover;
			min-width: 105vw;
			min-height: 105vh;
		}

		#CrackVideo {
		}
	}

	.innerContainer {
		max-width: 1200px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		min-width: 350px;

		.content.left {
			//		margin-left: 50%;
		}

		.content.center {
			/*text-align: center;
			max-width: 600px;
			margin: auto;*/
		}

		.content {
			padding-top: 120px;
			margin-left: 40px;
			margin-right: 40px;
			padding-bottom: 100px;
			text-align: center;

			.header {
				margin-bottom: 30px;
				margin-top: 75px;
				display: none;


				h2 {

					font-family: Work Sans;
					font-size: 32px;
					font-style: normal;
					font-weight: 400;
					line-height: 120%; /* 38.4px */
					letter-spacing: -0.64px;
				}

			}

			.subHeader.moveDown {
				margin-top: 100px;
			}

			.subHeader {
				color: #252423;
				font-family: $FONT_SERIF;
				font-size: 32px;
				font-style: normal;
				font-weight: 400;
				line-height: 120%; /* 38.4px */
				letter-spacing: -0.64px;

				max-width: 600px; //572px;


				@media only screen and (max-width: 850px) {
					font-size: 20px;
				}
			}

			.cracks {
				position: fixed;
				top: 50%;
				//left: 0%;
				opacity: 0;
				//width: 100vw;
				max-width: 572px;
				text-align: center;
				color: #252423;
				font-family: $FONT_SERIF;
				font-size: 36px;
				font-style: normal;
				font-weight: 400;
				line-height: 110%; /* 39.6px */
				letter-spacing: -1.08px;
				transform: translateY(100vh);
				margin-right: 80px;
				margin-left: 40px;

				@media only screen and (max-width: 850px) {
					font-size: 20px;
				}
			}

			.body.center {

				margin: 37px auto;

			}

			.body {
				font-family: $FONT_SERIF;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				color: $COLOR_BLACK;
				max-width: 313px;

			}
		}
	}
}


.cardPopupHowItWorks {
	//border-top: solid 1px rgba(37, 36, 35, 0.1);
	//transform: translateY(100vh);
	opacity: 1;
	//filter: drop-shadow(-17px 2px 10px rgba(0, 0, 0, 0.1));
	position: fixed;
	height: 100vh;
	top: 212px;
	width: 160vw;
	left: -30vw;
	//background-color: $COLOR_SAND;
	//max-width: 1200px;
	margin: auto;
	//z-index: 100;
	color: white;
	background-color: #252423;

	.innerContainer {
		max-width: 1200px;
		margin: auto;

		.content.left {
			margin-left: 50%;
		}

		.content.center {
			text-align: center;
			max-width: 600px;
			margin: auto;
		}

		.content {
			padding-top: 120px;
			margin-left: 40px;
			margin-right: 40px;
			padding-bottom: 100px;

			.header {
				display: none;
			}

			color: $COLOR_SAND;
			font-family: $FONT_SERIF;
			font-size: 36px;
			font-style: normal;
			font-weight: 400;

			.step {
				position: fixed;
				top: 50vh;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.step0 {
				font-family: Work Sans;
				font-size: 100px;
				font-style: normal;
				font-weight: 900;
				line-height: 90%; /* 90px */
				text-transform: uppercase;
				max-width: 650px;
				top: calc(50vh - 90px);
			}

			.cardStepHeader {

			}


			.subheader {
				color: $COLOR_SAND;
				font-family: $FONT;
				margin-top: 20px;
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 130%; /* 39.6px */
				letter-spacing: -1.08px;
				max-width: 572px;
			}

			.body.center {

				margin: 37px auto;

			}

			.body {
				font-family: $FONT_SERIF;
				font-size: 13px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				color: $COLOR_BLACK;
				max-width: 313px;

			}
		}
	}
}


#MainMenu {
	position: fixed;
	z-index: 1001;
}

.leftMenu {
	position: absolute;
	top: 40px;
	left: 40px;
	display: flex;
	display: none;

	@media only screen and (max-width: 460px) {
		display: none;
	}

	.menuItemLeft {
		color: $COLOR_SAND;
		font-family: $FONT;
		font-size: 10px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%; /* 12px */
		text-transform: uppercase;
		margin-right: 30px;
		cursor: pointer;
		transform: translateY(20px);
		opacity: 0;
	}
}

.rightMenu {
	position: fixed;
	top: 30px;
	right: 40px;

	@media only screen and (max-width: 460px) {
		top: 15px;
		right: 15px;
	}

	.menuItemRight {
		border-radius: 48px;
		background-color: $COLOR_SAND;
		padding: 18px 30px;
		color: $COLOR_BLACK;
		text-align: center;
		font-family: $FONT;
		font-size: 10px;
		font-style: normal;
		font-weight: 600;
		line-height: 120%; /* 12px */
		text-transform: uppercase;
		cursor: pointer;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		transform: translateY(20px);
		opacity: 0;

	}
}

#IntroContainer {
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: #1a1a1a;
	width: 100%;
	height: 100%;
	z-index: 100000000;
	overflow: clip;

	#MainIntro {
		-webkit-transform: translateX(-50%) translateY(-50%);
		-moz-transform: translateX(-50%) translateY(-50%);
		-ms-transform: translateX(-50%) translateY(-50%);
		-o-transform: translateX(-50%) translateY(-50%);
		transform: translateX(-50%) translateY(-50%);
		position: absolute;
		top: 50%;
		left: 50%;
		min-width: 100%;
		min-height: 100%;
		//width: auto;
		height: auto;
		width: 100%;

		z-index: 10;

		opacity: 0;

	}

	#PlayMainIntro {
		opacity: 0;
		position: absolute;
		border-radius: 48px;
		background: #FFFBE9;
		padding: 18px 30px;
		color: $COLOR_BLACK;
		display: flex;
		align-items: center;
		z-index: 11;

		.playVideoText {
			display: inline-block;
			font-family: $FONT;
			font-size: 10px;
			font-style: normal;
			font-weight: 700;
			//	line-height: 120%; /* 12px */
			text-transform: uppercase;
		}

		.playButton {
			display: inline-block;
			width: 13.856px;
			height: 12px;
			margin-left: 14px;

			svg {
				margin-bottom: 16px;
			}
		}
	}

	#SkipIntro {
		position: absolute;
		bottom: 40px;
		left: calc(50% - 60px);
		color: #FFFBE9;
		font-family: $FONT;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 90%; /* 14.4px */
		letter-spacing: -0.32px;
		text-transform: uppercase;
		z-index: 11;
		pointer-events: all;
		cursor: pointer;
		padding-bottom: 4px;
		border-bottom: 1px solid #FFFBE9;
		transform: translateY(100px);
	}

	#ProgressBar {
		position: absolute;
		bottom: 1px;
		left: 0px;
		width: 0%;
		height: 1px;
		background-color: $COLOR_SAND;
		z-index: 11;
		pointer-events: none;
	}

}


/* General Styles */

a,
a:visited,
a:active,
a:hover {
	text-decoration: underline;
	color: inherit;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
}

#ViewContainer {
	display: none;
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	//visibility: hidden;
}

#TemplateLayer {
	display: none;
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	min-height: 100vh;
}
