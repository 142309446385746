.HowItWorksModule {
	border-top: solid 1px rgba(37, 36, 35, 0.1);
	background-color: $COLOR_SAND;
	color: $COLOR_BLACK;
	z-index:2;
	position: relative;

	video {
		position: fixed;
		top:0px;
		left: 0px;
		width: 100%;
	}

	.innerContainer {
		max-width: 1200px;
		margin: auto;

		.content.left {
			margin-left: 50%;
		}

		.content.center {
			text-align: center;
			max-width: 600px;
			margin: auto;
		}

		.content.flex {
			display: flex;
			justify-content: space-between;

			@media only screen and (max-width: 950px) {
				display: block;
			}
		}

		.content {
			padding-top: 120px;
			margin-left: 40px;
			margin-right: 40px;
			padding-bottom: 100px;

			.header {
				margin-bottom: 100px;

				.ruHeader {
					color: $COLOR_RED;
					font-family: $FONT;
					font-size: 16px;
					font-style: normal;
					font-weight: 500;
					line-height: 120%; /* 19.2px */
					letter-spacing: -0.64px;
					margin-bottom: 11px;
				}

				h2 {

					font-family: Work Sans;
					font-size: 40px;
					font-style: normal;
					font-weight: 900;
					line-height: 80%; /* 32px */
					letter-spacing: -0.8px;
					text-transform: uppercase;
				}

			}

			.subHeader {
				color: #252423;
				font-family: $FONT_SERIF;
				font-size: 36px;
				font-style: normal;
				font-weight: 400;
				line-height: 110%; /* 39.6px */
				letter-spacing: -1.08px;
				max-width: 572px;
			}

			.body.center {

				margin: 37px auto;

			}

			.body {
				font-family: $FONT;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				color: $COLOR_BLACK;
				max-width: 570px;
			//	user-select: auto;

				@media only screen and (max-width: 950px) {
					max-width: none;
				}

				a {
					color: #B54141;
					//text-decoration: none;
				}

			}

			.faq {


				width: 100%;

				.questionContainer {
					width: 100%;
					.questions {
						border-top: solid 1px $COLOR_BLACK;

						//padding-bottom: 30px;
					//	width: 572px;

						.question {
							user-select: none;
							display: flex;
							justify-content: space-between;
							cursor: pointer;
							padding-top: 30px;
							padding-bottom: 30px;

							.questionText {
								font-family: $FONT;
								font-size: 16px;
								font-style: normal;
								font-weight: 900;
								line-height: 90%; /* 14.4px */
								text-transform: uppercase;
								max-width: calc(100% - 40px);
							}

							.plusCircle {
								width: 24px;
								height: 24px;
								border-radius: 24px;
								background-color: $COLOR_BLACK;
								margin-top: -7px;

								.line1 {
									width: 7px;
									height: 1px;
									background-color: $COLOR_SAND;
									position: relative;
									top: 12px;
									left: 9px;
								}

								.line2 {
									width: 1px;
									height: 7px;
									background-color: $COLOR_SAND;
									position: relative;
									top: 8px;
									left: 12px;
								}
							}
						}

						.answer {
							//display: none;
							height: 0px;
							font-family: $FONT;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: 120%; /* 16.8px */
							overflow: hidden;
							max-width: 650px;

							.spacer {
								height: 30px;
							}
						}


					}

					.endBorder {
						border-top: solid 1px $COLOR_BLACK;
					}

				}
			}


		}

		.stepsContent {
			text-align: center;
			max-width: 900px;
			margin: auto;
			padding-bottom: 200px;

			.steps {

				display: flex;
				justify-content: space-between;

				.step {

					.svg {

					}

					.stepText {
						max-width: 216px;

						.stepStep {
							margin-top: 60px;
							font-family: $FONT_SERIF;
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 100%; /* 12px */
							text-transform: capitalize;
						}

						.stepHeader {

							margin-top: 11px;
							font-size: 28px;
							font-style: normal;
							font-weight: 600;
							line-height: 100%; /* 28px */
							letter-spacing: -0.56px;
						}
					}
				}

			}
		}
	}

}
