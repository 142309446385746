@import 'eases';

$FONT: 'Work Sans', sans-serif;
$FONT_SERIF: 'Suisse Works','Times New Roman', sans-serif;


$COLOR_BLUE_LIGHT: #CFD7DF;
$COLOR_BLACK: #252423;
$COLOR_RED: #B54141;
$COLOR_SAND: #FFFBE9;
