.FooterModule {
	background-color: $COLOR_BLACK;
	width: 100vw;
	//height: 60px;
	color: $COLOR_SAND;
	position: relative;

	.innerContainer {
		padding-top: 25px;
		text-align: center;
		font-family: Work Sans;
		font-size: 10px;
		font-style: normal;
		font-weight: 600;
		line-height: 140%; /* 10px */
		text-transform: uppercase;
		margin-right: 20px;
		margin-left: 20px;
		padding-bottom: 25px;
	}
}
