.GetTheCodeModule {
	background-color: #000000;
	color: #CFD7DF;
	font-family: $FONT;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	//z-index:2;
	position: relative;
	z-index: 1002;
	//height: 100vh;
	border-top: solid 1px $COLOR_BLUE_LIGHT;

	.codeHeader {

		border-bottom: solid 1px $COLOR_BLUE_LIGHT;

		.innerContainerHeader {
			max-width: 1200px;
			margin: auto;

			.contentHeader {
				margin-top: 15px;
				margin-left: 40px;
				margin-right: 40px;
				margin-bottom: 15px;
				display: flex;

				.button {
					border-radius: 4px;
					border: 1px solid $COLOR_BLUE_LIGHT;
					padding: 8px 17px 8px 17px;
					font-family: $FONT;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					width: 28px;

				}

				.codeData {
					margin-left: 54px;
					margin-top: 10px;
				}
			}
		}
	}

	.innerContainer {
		max-width: 1200px;
		margin: auto;


		.content {

			padding-top: 40px;
			margin-left: 40px;
			margin-right: 40px;
			padding-bottom: 100px;


			.header {
				font-size: 40px;
				margin-bottom: 20px;
			}


			.buttons {
				position: sticky;
				pointer-events: all;
				top: calc(50vh + 20px);
				margin-left: calc(50% - 253px);
				display: flex;
				z-index: 2;

				@media only screen and (max-width: 600px) {
					display: block;
					margin-left:20px;
				}


				.copyTheCode {
					border-radius: 48px;
					background-color: $COLOR_SAND;
					padding: 18px 30px;
					color: $COLOR_BLACK;
					text-align: center;
					font-family: $FONT;
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: 120%; /* 16.8px */
					text-transform: uppercase;
					cursor: pointer;
					box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

					@media only screen and (max-width: 600px) {
						margin-top: 20px;
						margin-right: 20px;
						margin-left: -20px;
						width: calc(100% - 40px);
					}


					img {
						display: inline-block;
						margin-bottom: -3px;
						margin-left: 9px;
					}
				}

				.previewTheCode {
					border-radius: 48px;
					background-color: $COLOR_BLUE_LIGHT;
					padding: 18px 30px;
					color: $COLOR_BLACK;
					text-align: center;
					font-family: $FONT;
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: 120%; /* 16.8px */
					text-transform: uppercase;
					cursor: pointer;
					box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
					margin-right: 26px;


					@media only screen and (max-width: 600px) {
						margin-top: 20px;
						margin-right: 20px;
						margin-left: -20px;
						width: calc(100% - 40px);
					}


					img {
						display: inline-block;
						margin-bottom: -1px;
						margin-left: 9px;
					}
				}
			}

			.steps {
				position: sticky;
				top: calc(50vh - 100px);
				margin-left: calc(50% - 171px);
				margin-bottom: 250px;
				color: $COLOR_SAND;
				z-index: 1;

				.step {
					position: absolute;
					display: block;
					visibility: hidden;
					max-width: 352px;

					.back {
						margin-left: -20px;
						margin-bottom: 4px;
						cursor: pointer;
					}

					.stepHeadline {
						font-size: 24px;
						font-style: normal;
						font-weight: 700;
						line-height: 120%; /* 28.8px */
						text-transform: uppercase;
						margin-bottom:33px;
					}

					.stepText {
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 120%; /* 16.8px */
					}

					.testURL {
						margin-top: 15px;
						display: flex;
						border-bottom: solid 1px $COLOR_SAND;

						input {
							background-color: transparent;
							width: 80%;
							border: none;
							border-right:  solid 1px $COLOR_SAND;
							color: $COLOR_SAND;
						}

						.goButton {
							padding-left: 20px;
							padding-right: 20px;
							cursor: pointer;
						}
					}

					.largeHeadline {
						font-size: 64px;
						font-style: normal;
						font-weight: 800;
						line-height: 100%; /* 64px */
						text-transform: uppercase;
					}

					.button {
						margin-top: 33px;
						border-radius: 48px;
						background-color: $COLOR_SAND;
						padding: 18px 30px;
						color: $COLOR_BLACK;
						text-align: center;
						font-family: $FONT;
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: 120%; /* 16.8px */
						text-transform: uppercase;
						cursor: pointer;
						box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
					}
				}
			}

			.code {
				color: $COLOR_BLUE_LIGHT;
				font-family: $FONT;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				//	max-height: 100vh;
				overflow-x: hidden;
				overflow-y: hidden;
				margin-left: 110px;
				white-space: nowrap;
				opacity: 0.3;
				margin-top: -250px;

				@media only screen and (max-width: 850px) {
					margin-left: 0px;
					margin-top: -400px;
				}


				indent {
					display: inline-block;
					margin-left: 10px;
				}

			}
		}
	}


}
