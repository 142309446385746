.HeaderModule {
	position: fixed;
	width: 100vw;
	height: 100vh;
	/*background: url(/assets/images/topmerged.jpg) no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;*/
	pointer-events: none;
	//z-index: -1;

	.bgImages {
		pointer-events: none;
		position: fixed;

		.texture1 {
			position: absolute;
			top: 0px;
			min-width: 100vw;
			min-height: 100vh;
			image-rendering: pixelated;
		}

		.texture2 {
			position: absolute;
			top: 0px;
			min-width: 100vw;
			min-height: 100vh;
			image-rendering: pixelated;
		}
	}


	.innerContainer {
		//display: none;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		cursor: default;

		.mainText {
			@media only screen and (max-width: 850px) {
				transform: scale(0.7);
			}

			@media only screen and (max-width: 600px) {
				transform: scale(0.6);
			}

			@media only screen and (max-width: 500px) {
				transform: scale(0.5);
			}

			@media only screen and (max-width: 400px) {
				transform: scale(0.4);
			}

			@media only screen and (max-width: 320px) {
				transform: scale(0.3);
			}
		}


		.undoThe {

			overflow: hidden;
		}

		.firewall {
			font-family: 'Work Sans', sans-serif;
			font-weight: 900;
			display: inline-block;
			white-space: nowrap;
		}

		.undo {
			display: inline-block;
		}

		.the {
			display: inline-block;
		}


		.header {
			font-family: 'Work Sans', sans-serif;
			font-weight: 900;
			font-size: 120px;
			line-height: 100px;
			user-select: none;
			min-width: 758px;
			margin-bottom: 20px;



			.moveIn {
				font-family: 'Inter', sans-serif;
				margin-left: 150px;
				display: inline-block;
				overflow: hidden;

				.wall {

					display: inline-block;
					margin-left: -10px;
				}
			}
		}

		p {

			font-family: 'Work Sans', sans-serif;
			margin: auto;
			font-size: 14px;
			font-weight: 600;

			text-align: right;
			opacity: 0;
			//margin-right: 94px;

			.bringingRealNews {


			}

		}
	}

	.arrowDown {
		position: absolute;
		pointer-events: all;
		cursor: pointer;
		bottom: 30px;
		left: calc(50vw - 30px);

		@media only screen and (max-width: 600px) {
		//	display: none;
		}
	}

}
