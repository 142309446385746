.NewsHeadlinesModule {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0px;
	pointer-events: none;
	//z-index: -1;
	//pointer-events: none;
	//background-color: #333333;

	.content {

		height: 100vh;
		width: 100vw;


		.newsStory {




			@media only screen and (max-width: 1500px) {
				scale: 0.7;
				margin-left: -100px;
			}


			@media only screen and (max-width: 1100px) {
				scale: 0.5;
				margin-left: -100px;
			}

			@media only screen and (max-width: 1000px) {
				scale: 0.4;
				margin-left: -100px;
			}

			@media only screen and (max-width: 600px) {
				scale: 0.4;
				margin-left: -150px;
			}

		}

		.newsStory {
			user-select: none;
			position: fixed;
			width: 400px;
			text-align: center;

			.date {
				font-size: 12px;
				color: red;
			}

			.headline {
				font-size: 20px;
				margin-bottom: 20px;
				margin-bottom: 5px;
			}

			.source {
				font-size: 12px;
				color: #bbbbbb;
				margin-bottom: 10px;
			}
		}

		.newsStory:nth-child(1) {
			top: 50%;
			left: 40%;
			opacity: 0.2;
			filter: blur(5px);
			width: 183px;
			display: none;

			.date {
				color: $COLOR_SAND;
				font-size: 5.425px;
				font-style: normal;
				font-weight: 500;
				line-height: 100%; /* 5.425px */
				letter-spacing: -0.109px;
				text-transform: uppercase;
				margin-bottom: 8px;
			}

			.headline {
				font-size: 9.76px;
				font-style: normal;
				font-weight: 600;
				line-height: 140%; /* 13.664px */
				letter-spacing: -0.195px;
			}

			.source {
				display: none;
			}
		}

		.newsStory:nth-child(2) {
			top: 65%;
			left: 50%;
			width: 250px;
			opacity: 0.4;
			filter: blur(2px);

			.date {
				display: none;
			}

			.headline {
				font-family: Suisse Works;
				font-size: 11.685px;
				font-style: normal;
				font-weight: 400;
				line-height: 120%; /* 14.022px */
				letter-spacing: -0.234px;
			}

			.source {
				display: none;
			}
		}

		.newsStory:nth-child(3) {
			top: 100%;
			left: 80%;
			opacity: 0.3;
			filter: blur(7px);

			.date {

			}

			.headline {

			}

			.source {

			}
		}

		.newsStory:nth-child(4) {
			width: 335px;
			top: 60%;
			left: 5%;
			display: none;
			opacity: 0.6;
			filter: blur(0.5px);

			.date {
				font-size: 7.817px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%;
				letter-spacing: -0.156px;
				margin-bottom: 12px;
				color: $COLOR_SAND;
			}

			.headline {
				font-family: $FONT_SERIF;
				font-size: 10.05px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 14.07px */
				letter-spacing: -0.201px;
			}

			.source {
				display: none;
			}
		}

		.newsStory:nth-child(5) {
			top: 10%;
			left: 70%;
			opacity: 0.6;
			filter: blur(0.7px);

			.date {
				color: $COLOR_SAND;
				font-family: $FONT_SERIF;
				font-size: 8.656px;
				font-style: normal;
				font-weight: 500;
				line-height: 100%; /* 8.656px */
				letter-spacing: -0.173px;
				text-transform: uppercase;
				margin-bottom: 16px;
			}

			.headline {
				font-family: $FONT;
				font-size: 15.573px;
				font-style: normal;
				font-weight: 600;
				line-height: 140%; /* 21.803px */
				letter-spacing: -0.311px;
			}

			.source {
				display: none;
			}
		}

		.newsStory:nth-child(6) {
			top: 6%;
			left: 50%;
			opacity: 0.3;
			filter: blur(2px);

			.date {
				display: none;
			}

			.headline {
				font-family: $FONT_SERIF;
				font-size: 15.401px;
				font-style: normal;
				font-weight: 500;
				line-height: 120%; /* 18.481px */
				letter-spacing: -0.308px;
				margin-bottom: 12px
			}

			.source {
				font-size: 5.775px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 8.085px */
				letter-spacing: -0.116px;
			}
		}

		.newsStory:nth-child(7) {
			top: 32%;
			left: 0px;
			opacity: 0.7;
			filter: blur(3px);
			display: none;

			.date {
				font-size: 11.848px;
				font-style: normal;
				font-weight: 600;
				line-height: 100%; /* 11.848px */
				letter-spacing: -0.237px;
				text-transform: uppercase;
				margin-bottom: 28px;
			}

			.headline {
				font-size: 31.974px;
				font-style: normal;
				font-weight: 500;
				line-height: 120%; /* 38.369px */
				letter-spacing: -0.639px;
				margin-bottom: 36px;
			}

			.source {
				color: $COLOR_SAND;
				font-size: 11.99px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 16.786px */
				letter-spacing: -0.24px;
			}
		}

		.newsStory:nth-child(8) {
			top: 50%;
			left: 70%;

			.date {
				color: $COLOR_SAND;
				font-size: 8.893px;
				font-style: normal;
				font-weight: 500;
				line-height: 100%; /* 8.893px */
				letter-spacing: -0.178px;
				text-transform: uppercase;
				margin-bottom: 24px;
			}

			.headline {
				font-size: 24px;
				font-style: normal;
				font-weight: 900;
				line-height: 100%; /* 24px */
				text-transform: uppercase;

			}

			.source {
				display: none;
			}
		}

		.newsStory:nth-child(12) {
			top: 25%;
			left: 42%;
			width: 450px;

			.date {
				text-transform: uppercase;
				margin-bottom: 28px;

			}

			.headline {
				font-family: $FONT;
				font-size: 28.312px;
				font-style: normal;
				font-weight: 900;
				line-height: 100%; /* 28.312px */
				text-transform: uppercase;
			}

			.source {
				display: none;
			}
		}

		.newsStory:nth-child(10) {
			top: 5%;
			left: 10%;
			width: 454px;

			.date {
				color: $COLOR_SAND;
				font-size: 12.27px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 17.178px */
				letter-spacing: -0.245px;
				margin-bottom: 28px;
			}

			.headline {
				font-family: $FONT;
				font-size: 32.721px;
				font-style: normal;
				font-weight: 400;
				line-height: 120%; /* 39.265px */
				letter-spacing: -0.654px;
				margin-bottom: 36px;
			}

			.source {
				font-family: $FONT;
				font-size: 12.125px;
				font-style: normal;
				font-weight: 500;
				line-height: 100%; /* 12.125px */
				letter-spacing: -0.243px;
				text-transform: uppercase;
				color: $COLOR_SAND;
			}
		}

		.newsStory:nth-child(11) {
			top: calc(84% - 50px);
			left: 55%;
			width: 450px;
			opacity: 0.8;
			filter: blur(1px);

			.date {
				text-transform: uppercase;
				margin-bottom: 28px;

			}

			.headline {
				font-family: $FONT;
				font-size: 28.312px;
				font-style: normal;
				font-weight: 900;
				line-height: 100%; /* 28.312px */
				text-transform: uppercase;
			}

			.source {
				display: none;
			}
		}

		.newsStory:nth-child(9) {
			top: calc(80% - 150px);
			left: 10%;
			opacity: 0.9;
			filter: blur(3px);

			.date {
				font-size: 11.848px;
				font-style: normal;
				font-weight: 600;
				line-height: 100%; /* 11.848px */
				letter-spacing: -0.237px;
				text-transform: uppercase;
				margin-bottom: 28px;
			}

			.headline {
				font-size: 31.974px;
				font-style: normal;
				font-weight: 500;
				line-height: 120%; /* 38.369px */
				letter-spacing: -0.639px;
				margin-bottom: 36px;
			}

			.source {
				color: $COLOR_SAND;
				font-size: 11.99px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 16.786px */
				letter-spacing: -0.24px;
			}
		}
	}
}
