@font-face {
	font-family: 'Suisse Works';
	src: url("/assets/fonts/SuisseWorks-Regular-WebXL.woff") format("woff"), url("/assets/fonts/SuisseWorks-Regular-WebXL.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Suisse Works';
	src: url("/assets/fonts/SuisseWorks-RegularItalic-WebXL.woff") format("woff"), url("/assets/fonts/SuisseWorks-RegularItalic-WebXL.woff2") format("woff2");
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Suisse Works';
	src: url("/assets/fonts/SuisseWorks-Bold-WebXL.woff") format("woff"), url("/assets/fonts/SuisseWorks-Bold-WebXL.woff2") format("woff2");
	font-style: normal;
	font-weight: bold;
}

