@font-face {
  font-family: 'Suisse Works';
  src: url("/assets/fonts/SuisseWorks-Regular-WebXL.woff") format("woff"), url("/assets/fonts/SuisseWorks-Regular-WebXL.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Suisse Works';
  src: url("/assets/fonts/SuisseWorks-RegularItalic-WebXL.woff") format("woff"), url("/assets/fonts/SuisseWorks-RegularItalic-WebXL.woff2") format("woff2");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Suisse Works';
  src: url("/assets/fonts/SuisseWorks-Bold-WebXL.woff") format("woff"), url("/assets/fonts/SuisseWorks-Bold-WebXL.woff2") format("woff2");
  font-style: normal;
  font-weight: bold; }

html,
body {
  font-size: 22px;
  font-family: "Work Sans", sans-serif;
  vertical-align: baseline;
  font-weight: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior: none;
  overflow-x: hidden; }

* {
  user-select: none; }

text {
  font-family: "Work Sans", sans-serif; }

body {
  overflow-x: hidden;
  background-color: #1a1a1a;
  color: #FFFBE9; }

.topArea {
  pointer-events: none;
  height: 300vh; }

h1 {
  font-family: 'Inter', sans-serif;
  font-size: 120px;
  line-height: 100px; }

h2 {
  color: #252423;
  font-family: "Suisse Works", "Times New Roman", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 80%;
  /* 32px */
  letter-spacing: -0.8px; }

#ForegroundVideo {
  position: fixed;
  top: 0;
  z-index: 100000;
  pointer-events: none;
  opacity: 0.5;
  min-width: 100vw;
  min-height: 100vh; }

.cardContent {
  pointer-events: none; }

#VideoReveal {
  background-color: #FFFBE9; }

video {
  position: fixed;
  pointer-events: none;
  z-index: 100;
  top: 0px;
  left: 0px;
  width: 100vw;
  width: 100vw; }

.cardPopUp.darker {
  background-color: #F9F2D4; }

.cardPopUp.darkest {
  background-color: transparent;
  background-size: 200vw 412px;
  background-repeat: no-repeat;
  background-image: url(/assets/images/darkest_bg.png); }

.cardPopUp {
  pointer-events: none;
  /*-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;*/
  background-position: -400px -200px;
  transform: translateY(150vh);
  opacity: 1;
  position: fixed;
  height: calc(100vh + 300px);
  top: 0px;
  width: 100vw;
  left: 0vw;
  background-color: #FFFBE9;
  margin: auto;
  z-index: 10; }
  .cardPopUp .wall {
    position: relative;
    top: -125px;
    margin-left: 0vw;
    pointer-events: none; }
    .cardPopUp .wall img {
      width: 100vw; }
  .cardPopUp .crackedWall {
    position: absolute;
    top: 210px;
    left: 0vw; }
    .cardPopUp .crackedWall img {
      left: 0px;
      position: absolute;
      object-fit: cover;
      min-width: 105vw;
      min-height: 105vh; }
  .cardPopUp .innerContainer {
    max-width: 1200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 350px; }
    .cardPopUp .innerContainer .content.center {
      /*text-align: center;
			max-width: 600px;
			margin: auto;*/ }
    .cardPopUp .innerContainer .content {
      padding-top: 120px;
      margin-left: 40px;
      margin-right: 40px;
      padding-bottom: 100px;
      text-align: center; }
      .cardPopUp .innerContainer .content .header {
        margin-bottom: 30px;
        margin-top: 75px;
        display: none; }
        .cardPopUp .innerContainer .content .header h2 {
          font-family: Work Sans;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 38.4px */
          letter-spacing: -0.64px; }
      .cardPopUp .innerContainer .content .subHeader.moveDown {
        margin-top: 100px; }
      .cardPopUp .innerContainer .content .subHeader {
        color: #252423;
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 38.4px */
        letter-spacing: -0.64px;
        max-width: 600px; }
        @media only screen and (max-width: 850px) {
          .cardPopUp .innerContainer .content .subHeader {
            font-size: 20px; } }
      .cardPopUp .innerContainer .content .cracks {
        position: fixed;
        top: 50%;
        opacity: 0;
        max-width: 572px;
        text-align: center;
        color: #252423;
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        /* 39.6px */
        letter-spacing: -1.08px;
        transform: translateY(100vh);
        margin-right: 80px;
        margin-left: 40px; }
        @media only screen and (max-width: 850px) {
          .cardPopUp .innerContainer .content .cracks {
            font-size: 20px; } }
      .cardPopUp .innerContainer .content .body.center {
        margin: 37px auto; }
      .cardPopUp .innerContainer .content .body {
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #252423;
        max-width: 313px; }

.cardPopupHowItWorks {
  opacity: 1;
  position: fixed;
  height: 100vh;
  top: 212px;
  width: 160vw;
  left: -30vw;
  margin: auto;
  color: white;
  background-color: #252423; }
  .cardPopupHowItWorks .innerContainer {
    max-width: 1200px;
    margin: auto; }
    .cardPopupHowItWorks .innerContainer .content.left {
      margin-left: 50%; }
    .cardPopupHowItWorks .innerContainer .content.center {
      text-align: center;
      max-width: 600px;
      margin: auto; }
    .cardPopupHowItWorks .innerContainer .content {
      padding-top: 120px;
      margin-left: 40px;
      margin-right: 40px;
      padding-bottom: 100px;
      color: #FFFBE9;
      font-family: "Suisse Works", "Times New Roman", sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 400; }
      .cardPopupHowItWorks .innerContainer .content .header {
        display: none; }
      .cardPopupHowItWorks .innerContainer .content .step {
        position: fixed;
        top: 50vh;
        left: 50%;
        transform: translate(-50%, -50%); }
      .cardPopupHowItWorks .innerContainer .content .step0 {
        font-family: Work Sans;
        font-size: 100px;
        font-style: normal;
        font-weight: 900;
        line-height: 90%;
        /* 90px */
        text-transform: uppercase;
        max-width: 650px;
        top: calc(50vh - 90px); }
      .cardPopupHowItWorks .innerContainer .content .subheader {
        color: #FFFBE9;
        font-family: "Work Sans", sans-serif;
        margin-top: 20px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        /* 39.6px */
        letter-spacing: -1.08px;
        max-width: 572px; }
      .cardPopupHowItWorks .innerContainer .content .body.center {
        margin: 37px auto; }
      .cardPopupHowItWorks .innerContainer .content .body {
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #252423;
        max-width: 313px; }

#MainMenu {
  position: fixed;
  z-index: 1001; }

.leftMenu {
  position: absolute;
  top: 40px;
  left: 40px;
  display: flex;
  display: none; }
  @media only screen and (max-width: 460px) {
    .leftMenu {
      display: none; } }
  .leftMenu .menuItemLeft {
    color: #FFFBE9;
    font-family: "Work Sans", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 12px */
    text-transform: uppercase;
    margin-right: 30px;
    cursor: pointer;
    transform: translateY(20px);
    opacity: 0; }

.rightMenu {
  position: fixed;
  top: 30px;
  right: 40px; }
  @media only screen and (max-width: 460px) {
    .rightMenu {
      top: 15px;
      right: 15px; } }
  .rightMenu .menuItemRight {
    border-radius: 48px;
    background-color: #FFFBE9;
    padding: 18px 30px;
    color: #252423;
    text-align: center;
    font-family: "Work Sans", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 12px */
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    transform: translateY(20px);
    opacity: 0; }

#IntroContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #1a1a1a;
  width: 100%;
  height: 100%;
  z-index: 100000000;
  overflow: clip; }
  #IntroContainer #MainIntro {
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    width: 100%;
    z-index: 10;
    opacity: 0; }
  #IntroContainer #PlayMainIntro {
    opacity: 0;
    position: absolute;
    border-radius: 48px;
    background: #FFFBE9;
    padding: 18px 30px;
    color: #252423;
    display: flex;
    align-items: center;
    z-index: 11; }
    #IntroContainer #PlayMainIntro .playVideoText {
      display: inline-block;
      font-family: "Work Sans", sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase; }
    #IntroContainer #PlayMainIntro .playButton {
      display: inline-block;
      width: 13.856px;
      height: 12px;
      margin-left: 14px; }
      #IntroContainer #PlayMainIntro .playButton svg {
        margin-bottom: 16px; }
  #IntroContainer #SkipIntro {
    position: absolute;
    bottom: 40px;
    left: calc(50% - 60px);
    color: #FFFBE9;
    font-family: "Work Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%;
    /* 14.4px */
    letter-spacing: -0.32px;
    text-transform: uppercase;
    z-index: 11;
    pointer-events: all;
    cursor: pointer;
    padding-bottom: 4px;
    border-bottom: 1px solid #FFFBE9;
    transform: translateY(100px); }
  #IntroContainer #ProgressBar {
    position: absolute;
    bottom: 1px;
    left: 0px;
    width: 0%;
    height: 1px;
    background-color: #FFFBE9;
    z-index: 11;
    pointer-events: none; }

/* General Styles */
a,
a:visited,
a:active,
a:hover {
  text-decoration: underline;
  color: inherit; }

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0; }

#ViewContainer {
  display: none;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%; }

#TemplateLayer {
  display: none;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  min-height: 100vh; }

.AdvancedOptionModule {
  background-color: #CFD7DF;
  width: 100vw;
  height: auto;
  color: #252423;
  position: relative;
  z-index: 1002; }
  .AdvancedOptionModule .innerContainer {
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 49px;
    padding-bottom: 49px;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 16.8px */ }
    .AdvancedOptionModule .innerContainer .content {
      display: flex;
      justify-content: space-between; }
      @media only screen and (max-width: 1000px) {
        .AdvancedOptionModule .innerContainer .content {
          display: block; } }
      .AdvancedOptionModule .innerContainer .content .left {
        max-width: 400px;
        margin-right: 50px; }
        .AdvancedOptionModule .innerContainer .content .left .header {
          font-size: 32px;
          font-style: normal;
          font-weight: 900;
          line-height: 100%;
          /* 32px */
          letter-spacing: -0.64px;
          text-transform: uppercase;
          margin-bottom: 9px; }
      .AdvancedOptionModule .innerContainer .content .right {
        max-width: 400px; }
        .AdvancedOptionModule .innerContainer .content .right .link {
          margin-top: 9px;
          font-weight: 700;
          text-transform: uppercase; }

.CardModule.doubleHeight {
  height: 200vh; }

.CardModule {
  border-top: solid 1px rgba(37, 36, 35, 0.1);
  color: #252423;
  position: relative;
  height: 100vh; }
  .CardModule video {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%; }
  .CardModule .innerContainer {
    position: fixed;
    height: 100vh;
    top: 0px;
    width: 100vw;
    background-color: #FFFBE9;
    margin: auto; }
    .CardModule .innerContainer .content.left {
      margin-left: 50%; }
    .CardModule .innerContainer .content.center {
      text-align: center;
      max-width: 600px;
      margin: auto; }
    .CardModule .innerContainer .content {
      padding-top: 120px;
      margin-left: 40px;
      margin-right: 40px;
      padding-bottom: 100px; }
      .CardModule .innerContainer .content .header {
        margin-bottom: 100px; }
        .CardModule .innerContainer .content .header .ruHeader {
          color: #B54141;
          font-family: "Work Sans", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          /* 19.2px */
          letter-spacing: -0.64px;
          margin-bottom: 11px; }
        .CardModule .innerContainer .content .header h2 {
          font-family: Work Sans;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: 80%;
          /* 19.2px */
          letter-spacing: -0.48px; }
      .CardModule .innerContainer .content .subHeader {
        color: #252423;
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        /* 39.6px */
        letter-spacing: -1.08px;
        max-width: 572px; }
      .CardModule .innerContainer .content .body.center {
        margin: 37px auto; }
      .CardModule .innerContainer .content .body {
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #252423;
        max-width: 313px; }
    .CardModule .innerContainer .stepsContent {
      text-align: center;
      max-width: 900px;
      margin: auto;
      padding-bottom: 200px; }
      .CardModule .innerContainer .stepsContent .steps {
        display: flex;
        justify-content: space-between; }
        .CardModule .innerContainer .stepsContent .steps .step .stepText {
          max-width: 216px; }
          .CardModule .innerContainer .stepsContent .steps .step .stepText .stepStep {
            margin-top: 60px;
            font-family: "Suisse Works", "Times New Roman", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 12px */
            text-transform: capitalize; }
          .CardModule .innerContainer .stepsContent .steps .step .stepText .stepHeader {
            margin-top: 11px;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            /* 28px */
            letter-spacing: -0.56px; }

.EmptyModule {
  background-color: #252423;
  width: 100vw;
  height: 100vh; }

.FAQModule {
  background-color: #333333;
  color: white;
  z-index: 2;
  position: relative; }

.FooterModule {
  background-color: #252423;
  width: 100vw;
  color: #FFFBE9;
  position: relative; }
  .FooterModule .innerContainer {
    padding-top: 25px;
    text-align: center;
    font-family: Work Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 10px */
    text-transform: uppercase;
    margin-right: 20px;
    margin-left: 20px;
    padding-bottom: 25px; }

.GetTheCodeModule {
  background-color: #000000;
  color: #CFD7DF;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  z-index: 1002;
  border-top: solid 1px #CFD7DF; }
  .GetTheCodeModule .codeHeader {
    border-bottom: solid 1px #CFD7DF; }
    .GetTheCodeModule .codeHeader .innerContainerHeader {
      max-width: 1200px;
      margin: auto; }
      .GetTheCodeModule .codeHeader .innerContainerHeader .contentHeader {
        margin-top: 15px;
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 15px;
        display: flex; }
        .GetTheCodeModule .codeHeader .innerContainerHeader .contentHeader .button {
          border-radius: 4px;
          border: 1px solid #CFD7DF;
          padding: 8px 17px 8px 17px;
          font-family: "Work Sans", sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: 28px; }
        .GetTheCodeModule .codeHeader .innerContainerHeader .contentHeader .codeData {
          margin-left: 54px;
          margin-top: 10px; }
  .GetTheCodeModule .innerContainer {
    max-width: 1200px;
    margin: auto; }
    .GetTheCodeModule .innerContainer .content {
      padding-top: 40px;
      margin-left: 40px;
      margin-right: 40px;
      padding-bottom: 100px; }
      .GetTheCodeModule .innerContainer .content .header {
        font-size: 40px;
        margin-bottom: 20px; }
      .GetTheCodeModule .innerContainer .content .buttons {
        position: sticky;
        pointer-events: all;
        top: calc(50vh + 20px);
        margin-left: calc(50% - 253px);
        display: flex;
        z-index: 2; }
        @media only screen and (max-width: 600px) {
          .GetTheCodeModule .innerContainer .content .buttons {
            display: block;
            margin-left: 20px; } }
        .GetTheCodeModule .innerContainer .content .buttons .copyTheCode {
          border-radius: 48px;
          background-color: #FFFBE9;
          padding: 18px 30px;
          color: #252423;
          text-align: center;
          font-family: "Work Sans", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          /* 16.8px */
          text-transform: uppercase;
          cursor: pointer;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); }
          @media only screen and (max-width: 600px) {
            .GetTheCodeModule .innerContainer .content .buttons .copyTheCode {
              margin-top: 20px;
              margin-right: 20px;
              margin-left: -20px;
              width: calc(100% - 40px); } }
          .GetTheCodeModule .innerContainer .content .buttons .copyTheCode img {
            display: inline-block;
            margin-bottom: -3px;
            margin-left: 9px; }
        .GetTheCodeModule .innerContainer .content .buttons .previewTheCode {
          border-radius: 48px;
          background-color: #CFD7DF;
          padding: 18px 30px;
          color: #252423;
          text-align: center;
          font-family: "Work Sans", sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          /* 16.8px */
          text-transform: uppercase;
          cursor: pointer;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          margin-right: 26px; }
          @media only screen and (max-width: 600px) {
            .GetTheCodeModule .innerContainer .content .buttons .previewTheCode {
              margin-top: 20px;
              margin-right: 20px;
              margin-left: -20px;
              width: calc(100% - 40px); } }
          .GetTheCodeModule .innerContainer .content .buttons .previewTheCode img {
            display: inline-block;
            margin-bottom: -1px;
            margin-left: 9px; }
      .GetTheCodeModule .innerContainer .content .steps {
        position: sticky;
        top: calc(50vh - 100px);
        margin-left: calc(50% - 171px);
        margin-bottom: 250px;
        color: #FFFBE9;
        z-index: 1; }
        .GetTheCodeModule .innerContainer .content .steps .step {
          position: absolute;
          display: block;
          visibility: hidden;
          max-width: 352px; }
          .GetTheCodeModule .innerContainer .content .steps .step .back {
            margin-left: -20px;
            margin-bottom: 4px;
            cursor: pointer; }
          .GetTheCodeModule .innerContainer .content .steps .step .stepHeadline {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 28.8px */
            text-transform: uppercase;
            margin-bottom: 33px; }
          .GetTheCodeModule .innerContainer .content .steps .step .stepText {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 16.8px */ }
          .GetTheCodeModule .innerContainer .content .steps .step .testURL {
            margin-top: 15px;
            display: flex;
            border-bottom: solid 1px #FFFBE9; }
            .GetTheCodeModule .innerContainer .content .steps .step .testURL input {
              background-color: transparent;
              width: 80%;
              border: none;
              border-right: solid 1px #FFFBE9;
              color: #FFFBE9; }
            .GetTheCodeModule .innerContainer .content .steps .step .testURL .goButton {
              padding-left: 20px;
              padding-right: 20px;
              cursor: pointer; }
          .GetTheCodeModule .innerContainer .content .steps .step .largeHeadline {
            font-size: 64px;
            font-style: normal;
            font-weight: 800;
            line-height: 100%;
            /* 64px */
            text-transform: uppercase; }
          .GetTheCodeModule .innerContainer .content .steps .step .button {
            margin-top: 33px;
            border-radius: 48px;
            background-color: #FFFBE9;
            padding: 18px 30px;
            color: #252423;
            text-align: center;
            font-family: "Work Sans", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 16.8px */
            text-transform: uppercase;
            cursor: pointer;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); }
      .GetTheCodeModule .innerContainer .content .code {
        color: #CFD7DF;
        font-family: "Work Sans", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        overflow-x: hidden;
        overflow-y: hidden;
        margin-left: 110px;
        white-space: nowrap;
        opacity: 0.3;
        margin-top: -250px; }
        @media only screen and (max-width: 850px) {
          .GetTheCodeModule .innerContainer .content .code {
            margin-left: 0px;
            margin-top: -400px; } }
        .GetTheCodeModule .innerContainer .content .code indent {
          display: inline-block;
          margin-left: 10px; }

.HeaderModule {
  position: fixed;
  width: 100vw;
  height: 100vh;
  /*background: url(/assets/images/topmerged.jpg) no-repeat center center fixed;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;*/
  pointer-events: none; }
  .HeaderModule .bgImages {
    pointer-events: none;
    position: fixed; }
    .HeaderModule .bgImages .texture1 {
      position: absolute;
      top: 0px;
      min-width: 100vw;
      min-height: 100vh;
      image-rendering: pixelated; }
    .HeaderModule .bgImages .texture2 {
      position: absolute;
      top: 0px;
      min-width: 100vw;
      min-height: 100vh;
      image-rendering: pixelated; }
  .HeaderModule .innerContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: default; }
    @media only screen and (max-width: 850px) {
      .HeaderModule .innerContainer .mainText {
        transform: scale(0.7); } }
    @media only screen and (max-width: 600px) {
      .HeaderModule .innerContainer .mainText {
        transform: scale(0.6); } }
    @media only screen and (max-width: 500px) {
      .HeaderModule .innerContainer .mainText {
        transform: scale(0.5); } }
    @media only screen and (max-width: 400px) {
      .HeaderModule .innerContainer .mainText {
        transform: scale(0.4); } }
    @media only screen and (max-width: 320px) {
      .HeaderModule .innerContainer .mainText {
        transform: scale(0.3); } }
    .HeaderModule .innerContainer .undoThe {
      overflow: hidden; }
    .HeaderModule .innerContainer .firewall {
      font-family: 'Work Sans', sans-serif;
      font-weight: 900;
      display: inline-block;
      white-space: nowrap; }
    .HeaderModule .innerContainer .undo {
      display: inline-block; }
    .HeaderModule .innerContainer .the {
      display: inline-block; }
    .HeaderModule .innerContainer .header {
      font-family: 'Work Sans', sans-serif;
      font-weight: 900;
      font-size: 120px;
      line-height: 100px;
      user-select: none;
      min-width: 758px;
      margin-bottom: 20px; }
      .HeaderModule .innerContainer .header .moveIn {
        font-family: 'Inter', sans-serif;
        margin-left: 150px;
        display: inline-block;
        overflow: hidden; }
        .HeaderModule .innerContainer .header .moveIn .wall {
          display: inline-block;
          margin-left: -10px; }
    .HeaderModule .innerContainer p {
      font-family: 'Work Sans', sans-serif;
      margin: auto;
      font-size: 14px;
      font-weight: 600;
      text-align: right;
      opacity: 0; }
  .HeaderModule .arrowDown {
    position: absolute;
    pointer-events: all;
    cursor: pointer;
    bottom: 30px;
    left: calc(50vw - 30px); }

.HowItWorksModule {
  border-top: solid 1px rgba(37, 36, 35, 0.1);
  background-color: #FFFBE9;
  color: #252423;
  z-index: 2;
  position: relative; }
  .HowItWorksModule video {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%; }
  .HowItWorksModule .innerContainer {
    max-width: 1200px;
    margin: auto; }
    .HowItWorksModule .innerContainer .content.left {
      margin-left: 50%; }
    .HowItWorksModule .innerContainer .content.center {
      text-align: center;
      max-width: 600px;
      margin: auto; }
    .HowItWorksModule .innerContainer .content.flex {
      display: flex;
      justify-content: space-between; }
      @media only screen and (max-width: 950px) {
        .HowItWorksModule .innerContainer .content.flex {
          display: block; } }
    .HowItWorksModule .innerContainer .content {
      padding-top: 120px;
      margin-left: 40px;
      margin-right: 40px;
      padding-bottom: 100px; }
      .HowItWorksModule .innerContainer .content .header {
        margin-bottom: 100px; }
        .HowItWorksModule .innerContainer .content .header .ruHeader {
          color: #B54141;
          font-family: "Work Sans", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          /* 19.2px */
          letter-spacing: -0.64px;
          margin-bottom: 11px; }
        .HowItWorksModule .innerContainer .content .header h2 {
          font-family: Work Sans;
          font-size: 40px;
          font-style: normal;
          font-weight: 900;
          line-height: 80%;
          /* 32px */
          letter-spacing: -0.8px;
          text-transform: uppercase; }
      .HowItWorksModule .innerContainer .content .subHeader {
        color: #252423;
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        /* 39.6px */
        letter-spacing: -1.08px;
        max-width: 572px; }
      .HowItWorksModule .innerContainer .content .body.center {
        margin: 37px auto; }
      .HowItWorksModule .innerContainer .content .body {
        font-family: "Work Sans", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #252423;
        max-width: 570px; }
        @media only screen and (max-width: 950px) {
          .HowItWorksModule .innerContainer .content .body {
            max-width: none; } }
        .HowItWorksModule .innerContainer .content .body a {
          color: #B54141; }
      .HowItWorksModule .innerContainer .content .faq {
        width: 100%; }
        .HowItWorksModule .innerContainer .content .faq .questionContainer {
          width: 100%; }
          .HowItWorksModule .innerContainer .content .faq .questionContainer .questions {
            border-top: solid 1px #252423; }
            .HowItWorksModule .innerContainer .content .faq .questionContainer .questions .question {
              user-select: none;
              display: flex;
              justify-content: space-between;
              cursor: pointer;
              padding-top: 30px;
              padding-bottom: 30px; }
              .HowItWorksModule .innerContainer .content .faq .questionContainer .questions .question .questionText {
                font-family: "Work Sans", sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 900;
                line-height: 90%;
                /* 14.4px */
                text-transform: uppercase;
                max-width: calc(100% - 40px); }
              .HowItWorksModule .innerContainer .content .faq .questionContainer .questions .question .plusCircle {
                width: 24px;
                height: 24px;
                border-radius: 24px;
                background-color: #252423;
                margin-top: -7px; }
                .HowItWorksModule .innerContainer .content .faq .questionContainer .questions .question .plusCircle .line1 {
                  width: 7px;
                  height: 1px;
                  background-color: #FFFBE9;
                  position: relative;
                  top: 12px;
                  left: 9px; }
                .HowItWorksModule .innerContainer .content .faq .questionContainer .questions .question .plusCircle .line2 {
                  width: 1px;
                  height: 7px;
                  background-color: #FFFBE9;
                  position: relative;
                  top: 8px;
                  left: 12px; }
            .HowItWorksModule .innerContainer .content .faq .questionContainer .questions .answer {
              height: 0px;
              font-family: "Work Sans", sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              /* 16.8px */
              overflow: hidden;
              max-width: 650px; }
              .HowItWorksModule .innerContainer .content .faq .questionContainer .questions .answer .spacer {
                height: 30px; }
          .HowItWorksModule .innerContainer .content .faq .questionContainer .endBorder {
            border-top: solid 1px #252423; }
    .HowItWorksModule .innerContainer .stepsContent {
      text-align: center;
      max-width: 900px;
      margin: auto;
      padding-bottom: 200px; }
      .HowItWorksModule .innerContainer .stepsContent .steps {
        display: flex;
        justify-content: space-between; }
        .HowItWorksModule .innerContainer .stepsContent .steps .step .stepText {
          max-width: 216px; }
          .HowItWorksModule .innerContainer .stepsContent .steps .step .stepText .stepStep {
            margin-top: 60px;
            font-family: "Suisse Works", "Times New Roman", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 12px */
            text-transform: capitalize; }
          .HowItWorksModule .innerContainer .stepsContent .steps .step .stepText .stepHeader {
            margin-top: 11px;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            /* 28px */
            letter-spacing: -0.56px; }

.HowItWorksSteps {
  border-top: solid 1px rgba(37, 36, 35, 0.1);
  background-color: #252423;
  color: #FFFBE9;
  z-index: 3;
  position: relative;
  width: 100vw;
  height: auto; }
  @media only screen and (max-width: 650px) {
    .HowItWorksSteps {
      height: auto; } }
  .HowItWorksSteps .innerContainer {
    max-width: 640px;
    margin: auto;
    text-align: center; }
    .HowItWorksSteps .innerContainer .header {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-family: "Work Sans", sans-serif;
      font-size: 100px;
      font-style: normal;
      font-weight: 900;
      line-height: 90%;
      /* 90px */
      text-transform: uppercase;
      height: 100vh; }
      @media only screen and (max-width: 650px) {
        .HowItWorksSteps .innerContainer .header {
          font-size: 60px;
          padding-left: 40px;
          padding-right: 40px;
          height: 50vh; } }
    .HowItWorksSteps .innerContainer .step.step3 {
      margin-bottom: 0px; }
    .HowItWorksSteps .innerContainer .step {
      height: auto;
      /*display: flex;
			justify-content: center;
			align-items: center;*/
      margin-bottom: 200px; }
      @media only screen and (max-width: 650px) {
        .HowItWorksSteps .innerContainer .step {
          height: auto; } }
      .HowItWorksSteps .innerContainer .step .stepInner {
        max-width: calc(100% - 40px);
        margin-right: 20px;
        margin-left: 20px; }
        .HowItWorksSteps .innerContainer .step .stepInner .cardStepStep {
          font-family: "Suisse Works", "Times New Roman", sans-serif;
          font-size: 12px;
          margin-bottom: 12px; }
        .HowItWorksSteps .innerContainer .step .stepInner .cardStepHeader {
          font-family: "Suisse Works", "Times New Roman", sans-serif;
          font-size: 32px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          /* 35.2px */
          letter-spacing: -0.96px;
          max-width: 300px;
          margin: 0 auto;
          margin-bottom: 40px; }
        .HowItWorksSteps .innerContainer .step .stepInner .cardStepBody {
          font-family: "Suisse Works", "Times New Roman", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          max-width: 320px;
          margin: 0 auto; }
      .HowItWorksSteps .innerContainer .step .placeIt {
        margin-left: -250px; }
        @media only screen and (max-width: 650px) {
          .HowItWorksSteps .innerContainer .step .placeIt {
            margin-left: -150px; } }
      .HowItWorksSteps .innerContainer .step video {
        position: static;
        width: 700px;
        margin-left: -30px; }
        @media only screen and (max-width: 650px) {
          .HowItWorksSteps .innerContainer .step video {
            width: 400px; } }
      .HowItWorksSteps .innerContainer .step .video3 {
        margin-left: -40px; }

.HowItWorksModule.onTop {
  z-index: 1000; }

.HowItWorksVideoModule {
  background-color: #252423;
  color: #FFFBE9;
  height: 150vh; }
  .HowItWorksVideoModule video {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%; }
  .HowItWorksVideoModule .innerContainer {
    max-width: 1200px;
    margin: auto; }
    .HowItWorksVideoModule .innerContainer .content.left {
      margin-left: 50%; }
    .HowItWorksVideoModule .innerContainer .content.center {
      text-align: center;
      max-width: 600px;
      margin: auto; }
    .HowItWorksVideoModule .innerContainer .content {
      padding-top: 120px;
      margin-left: 40px;
      margin-right: 40px;
      padding-bottom: 100px; }
      .HowItWorksVideoModule .innerContainer .content .header {
        margin-bottom: 100px; }
        .HowItWorksVideoModule .innerContainer .content .header .ruHeader {
          color: #B54141;
          font-family: "Work Sans", sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          /* 19.2px */
          letter-spacing: -0.64px;
          margin-bottom: 11px; }
        .HowItWorksVideoModule .innerContainer .content .header h2 {
          font-family: Work Sans;
          font-size: 40px;
          font-style: normal;
          font-weight: 900;
          line-height: 80%;
          /* 32px */
          letter-spacing: -0.8px; }
      .HowItWorksVideoModule .innerContainer .content .subHeader {
        color: #252423;
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        /* 39.6px */
        letter-spacing: -1.08px;
        max-width: 572px; }
      .HowItWorksVideoModule .innerContainer .content .body.center {
        margin: 37px auto; }
      .HowItWorksVideoModule .innerContainer .content .body {
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #252423;
        max-width: 313px; }
      .HowItWorksVideoModule .innerContainer .content .faq {
        display: flex;
        justify-content: space-between; }
        .HowItWorksVideoModule .innerContainer .content .faq .body {
          max-width: 300px; }
        .HowItWorksVideoModule .innerContainer .content .faq .questionContainer .questions {
          border-top: solid 1px #252423;
          width: 572px; }
          .HowItWorksVideoModule .innerContainer .content .faq .questionContainer .questions .question {
            user-select: none;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            padding-top: 30px;
            padding-bottom: 30px; }
            .HowItWorksVideoModule .innerContainer .content .faq .questionContainer .questions .question .questionText {
              font-family: "Work Sans", sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 900;
              line-height: 90%;
              /* 14.4px */
              text-transform: uppercase; }
            .HowItWorksVideoModule .innerContainer .content .faq .questionContainer .questions .question .plusCircle {
              width: 24px;
              height: 24px;
              border-radius: 24px;
              background-color: #252423;
              margin-top: -7px; }
              .HowItWorksVideoModule .innerContainer .content .faq .questionContainer .questions .question .plusCircle .line1 {
                width: 7px;
                height: 1px;
                background-color: #FFFBE9;
                position: relative;
                top: 12px;
                left: 9px; }
              .HowItWorksVideoModule .innerContainer .content .faq .questionContainer .questions .question .plusCircle .line2 {
                width: 1px;
                height: 7px;
                background-color: #FFFBE9;
                position: relative;
                top: 8px;
                left: 12px; }
          .HowItWorksVideoModule .innerContainer .content .faq .questionContainer .questions .answer {
            height: 0px;
            font-family: "Work Sans", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 16.8px */
            overflow: hidden;
            max-width: 450px; }
            .HowItWorksVideoModule .innerContainer .content .faq .questionContainer .questions .answer .spacer {
              height: 30px; }
        .HowItWorksVideoModule .innerContainer .content .faq .questionContainer .endBorder {
          border-top: solid 1px #252423; }
    .HowItWorksVideoModule .innerContainer .stepsContent {
      text-align: center;
      max-width: 900px;
      margin: auto;
      padding-bottom: 200px; }
      .HowItWorksVideoModule .innerContainer .stepsContent .steps {
        display: flex;
        justify-content: space-between; }
        .HowItWorksVideoModule .innerContainer .stepsContent .steps .step .stepText {
          max-width: 216px; }
          .HowItWorksVideoModule .innerContainer .stepsContent .steps .step .stepText .stepStep {
            margin-top: 60px;
            font-family: "Suisse Works", "Times New Roman", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 12px */
            text-transform: capitalize; }
          .HowItWorksVideoModule .innerContainer .stepsContent .steps .step .stepText .stepHeader {
            margin-top: 11px;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            /* 28px */
            letter-spacing: -0.56px; }

.NewsHeadlinesModule {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0px;
  pointer-events: none; }
  .NewsHeadlinesModule .content {
    height: 100vh;
    width: 100vw; }
    @media only screen and (max-width: 1500px) {
      .NewsHeadlinesModule .content .newsStory {
        scale: 0.7;
        margin-left: -100px; } }
    @media only screen and (max-width: 1100px) {
      .NewsHeadlinesModule .content .newsStory {
        scale: 0.5;
        margin-left: -100px; } }
    @media only screen and (max-width: 1000px) {
      .NewsHeadlinesModule .content .newsStory {
        scale: 0.4;
        margin-left: -100px; } }
    @media only screen and (max-width: 600px) {
      .NewsHeadlinesModule .content .newsStory {
        scale: 0.4;
        margin-left: -150px; } }
    .NewsHeadlinesModule .content .newsStory {
      user-select: none;
      position: fixed;
      width: 400px;
      text-align: center; }
      .NewsHeadlinesModule .content .newsStory .date {
        font-size: 12px;
        color: red; }
      .NewsHeadlinesModule .content .newsStory .headline {
        font-size: 20px;
        margin-bottom: 20px;
        margin-bottom: 5px; }
      .NewsHeadlinesModule .content .newsStory .source {
        font-size: 12px;
        color: #bbbbbb;
        margin-bottom: 10px; }
    .NewsHeadlinesModule .content .newsStory:nth-child(1) {
      top: 50%;
      left: 40%;
      opacity: 0.2;
      filter: blur(5px);
      width: 183px;
      display: none; }
      .NewsHeadlinesModule .content .newsStory:nth-child(1) .date {
        color: #FFFBE9;
        font-size: 5.425px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 5.425px */
        letter-spacing: -0.109px;
        text-transform: uppercase;
        margin-bottom: 8px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(1) .headline {
        font-size: 9.76px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 13.664px */
        letter-spacing: -0.195px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(1) .source {
        display: none; }
    .NewsHeadlinesModule .content .newsStory:nth-child(2) {
      top: 65%;
      left: 50%;
      width: 250px;
      opacity: 0.4;
      filter: blur(2px); }
      .NewsHeadlinesModule .content .newsStory:nth-child(2) .date {
        display: none; }
      .NewsHeadlinesModule .content .newsStory:nth-child(2) .headline {
        font-family: Suisse Works;
        font-size: 11.685px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 14.022px */
        letter-spacing: -0.234px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(2) .source {
        display: none; }
    .NewsHeadlinesModule .content .newsStory:nth-child(3) {
      top: 100%;
      left: 80%;
      opacity: 0.3;
      filter: blur(7px); }
    .NewsHeadlinesModule .content .newsStory:nth-child(4) {
      width: 335px;
      top: 60%;
      left: 5%;
      display: none;
      opacity: 0.6;
      filter: blur(0.5px); }
      .NewsHeadlinesModule .content .newsStory:nth-child(4) .date {
        font-size: 7.817px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: -0.156px;
        margin-bottom: 12px;
        color: #FFFBE9; }
      .NewsHeadlinesModule .content .newsStory:nth-child(4) .headline {
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 10.05px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 14.07px */
        letter-spacing: -0.201px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(4) .source {
        display: none; }
    .NewsHeadlinesModule .content .newsStory:nth-child(5) {
      top: 10%;
      left: 70%;
      opacity: 0.6;
      filter: blur(0.7px); }
      .NewsHeadlinesModule .content .newsStory:nth-child(5) .date {
        color: #FFFBE9;
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 8.656px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 8.656px */
        letter-spacing: -0.173px;
        text-transform: uppercase;
        margin-bottom: 16px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(5) .headline {
        font-family: "Work Sans", sans-serif;
        font-size: 15.573px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 21.803px */
        letter-spacing: -0.311px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(5) .source {
        display: none; }
    .NewsHeadlinesModule .content .newsStory:nth-child(6) {
      top: 6%;
      left: 50%;
      opacity: 0.3;
      filter: blur(2px); }
      .NewsHeadlinesModule .content .newsStory:nth-child(6) .date {
        display: none; }
      .NewsHeadlinesModule .content .newsStory:nth-child(6) .headline {
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 15.401px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 18.481px */
        letter-spacing: -0.308px;
        margin-bottom: 12px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(6) .source {
        font-size: 5.775px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 8.085px */
        letter-spacing: -0.116px; }
    .NewsHeadlinesModule .content .newsStory:nth-child(7) {
      top: 32%;
      left: 0px;
      opacity: 0.7;
      filter: blur(3px);
      display: none; }
      .NewsHeadlinesModule .content .newsStory:nth-child(7) .date {
        font-size: 11.848px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        /* 11.848px */
        letter-spacing: -0.237px;
        text-transform: uppercase;
        margin-bottom: 28px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(7) .headline {
        font-size: 31.974px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 38.369px */
        letter-spacing: -0.639px;
        margin-bottom: 36px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(7) .source {
        color: #FFFBE9;
        font-size: 11.99px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 16.786px */
        letter-spacing: -0.24px; }
    .NewsHeadlinesModule .content .newsStory:nth-child(8) {
      top: 50%;
      left: 70%; }
      .NewsHeadlinesModule .content .newsStory:nth-child(8) .date {
        color: #FFFBE9;
        font-size: 8.893px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 8.893px */
        letter-spacing: -0.178px;
        text-transform: uppercase;
        margin-bottom: 24px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(8) .headline {
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        /* 24px */
        text-transform: uppercase; }
      .NewsHeadlinesModule .content .newsStory:nth-child(8) .source {
        display: none; }
    .NewsHeadlinesModule .content .newsStory:nth-child(12) {
      top: 25%;
      left: 42%;
      width: 450px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(12) .date {
        text-transform: uppercase;
        margin-bottom: 28px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(12) .headline {
        font-family: "Work Sans", sans-serif;
        font-size: 28.312px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        /* 28.312px */
        text-transform: uppercase; }
      .NewsHeadlinesModule .content .newsStory:nth-child(12) .source {
        display: none; }
    .NewsHeadlinesModule .content .newsStory:nth-child(10) {
      top: 5%;
      left: 10%;
      width: 454px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(10) .date {
        color: #FFFBE9;
        font-size: 12.27px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 17.178px */
        letter-spacing: -0.245px;
        margin-bottom: 28px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(10) .headline {
        font-family: "Work Sans", sans-serif;
        font-size: 32.721px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        /* 39.265px */
        letter-spacing: -0.654px;
        margin-bottom: 36px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(10) .source {
        font-family: "Work Sans", sans-serif;
        font-size: 12.125px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 12.125px */
        letter-spacing: -0.243px;
        text-transform: uppercase;
        color: #FFFBE9; }
    .NewsHeadlinesModule .content .newsStory:nth-child(11) {
      top: calc(84% - 50px);
      left: 55%;
      width: 450px;
      opacity: 0.8;
      filter: blur(1px); }
      .NewsHeadlinesModule .content .newsStory:nth-child(11) .date {
        text-transform: uppercase;
        margin-bottom: 28px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(11) .headline {
        font-family: "Work Sans", sans-serif;
        font-size: 28.312px;
        font-style: normal;
        font-weight: 900;
        line-height: 100%;
        /* 28.312px */
        text-transform: uppercase; }
      .NewsHeadlinesModule .content .newsStory:nth-child(11) .source {
        display: none; }
    .NewsHeadlinesModule .content .newsStory:nth-child(9) {
      top: calc(80% - 150px);
      left: 10%;
      opacity: 0.9;
      filter: blur(3px); }
      .NewsHeadlinesModule .content .newsStory:nth-child(9) .date {
        font-size: 11.848px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        /* 11.848px */
        letter-spacing: -0.237px;
        text-transform: uppercase;
        margin-bottom: 28px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(9) .headline {
        font-size: 31.974px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 38.369px */
        letter-spacing: -0.639px;
        margin-bottom: 36px; }
      .NewsHeadlinesModule .content .newsStory:nth-child(9) .source {
        color: #FFFBE9;
        font-size: 11.99px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 16.786px */
        letter-spacing: -0.24px; }

.SpreadTheWordModule {
  border-top: solid 1px rgba(37, 36, 35, 0.1);
  background-color: #FFFBE9;
  color: #252423;
  position: relative;
  z-index: 1000;
  padding-right: 40px;
  padding-left: 40px;
  overflow: clip; }
  .SpreadTheWordModule .innerContainer {
    max-width: 1200px;
    margin: auto; }
    .SpreadTheWordModule .innerContainer .content.center {
      text-align: center;
      max-width: 1200px;
      margin: auto; }
    .SpreadTheWordModule .innerContainer .content {
      padding-top: 120px;
      margin-left: 40px;
      margin-right: 40px;
      padding-bottom: 100px; }
      .SpreadTheWordModule .innerContainer .content .header {
        margin-bottom: 40px; }
        .SpreadTheWordModule .innerContainer .content .header .join {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 90%;
          /* 14.4px */
          letter-spacing: -0.32px;
          text-transform: uppercase;
          margin-bottom: 60px; }
        .SpreadTheWordModule .innerContainer .content .header h2 {
          text-align: center;
          font-family: Work Sans;
          font-size: 120px;
          font-style: normal;
          font-weight: 900;
          line-height: 90%;
          /* 120px */
          text-transform: uppercase; }
          @media only screen and (max-width: 1000px) {
            .SpreadTheWordModule .innerContainer .content .header h2 {
              font-size: 64px;
              font-style: normal;
              font-weight: 900;
              line-height: 90%;
              /* 57.6px */
              text-transform: uppercase; } }
        .SpreadTheWordModule .innerContainer .content .header .italic {
          font-size: 120px;
          font-style: italic;
          font-weight: 300;
          line-height: 100%;
          letter-spacing: -3.6px; }
      .SpreadTheWordModule .innerContainer .content .subHeader {
        color: #252423;
        font-family: "Suisse Works", "Times New Roman", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
        max-width: 454px;
        margin: 0 auto; }
      .SpreadTheWordModule .innerContainer .content .body.center {
        margin: 37px auto; }
      .SpreadTheWordModule .innerContainer .content .hashtag {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 90%;
        /* 14.4px */
        letter-spacing: -0.32px;
        text-transform: uppercase;
        margin-top: 40px;
        margin-bottom: 168px; }
      .SpreadTheWordModule .innerContainer .content .graphics {
        display: flex;
        justify-content: center; }
        @media only screen and (max-width: 1000px) {
          .SpreadTheWordModule .innerContainer .content .graphics {
            display: block; } }
        .SpreadTheWordModule .innerContainer .content .graphics .graphicsToDownload {
          perspective: 400px;
          perspective-origin: 50% -20%; }
          .SpreadTheWordModule .innerContainer .content .graphics .graphicsToDownload img {
            max-width: 100%; }
        .SpreadTheWordModule .innerContainer .content .graphics .graphic1 {
          margin-right: 40px; }
          @media only screen and (max-width: 1000px) {
            .SpreadTheWordModule .innerContainer .content .graphics .graphic1 {
              margin-right: 0px;
              margin-bottom: 100px; } }
        .SpreadTheWordModule .innerContainer .content .graphics .graphic .download {
          margin-top: 40px;
          display: flex;
          justify-content: center; }
        .SpreadTheWordModule .innerContainer .content .graphics .graphic .download1 {
          cursor: pointer;
          margin-right: 10px; }
        .SpreadTheWordModule .innerContainer .content .graphics .graphic .download2 {
          cursor: pointer; }

.view {
  width: 100%;
  overflow: clip; }
