.SpreadTheWordModule {
	border-top: solid 1px rgba(37, 36, 35, 0.1);
	background-color: $COLOR_SAND;
	color: $COLOR_BLACK;
	//z-index:2;
	position: relative;
	z-index: 1000;
	padding-right: 40px;
	padding-left: 40px;
	overflow: clip;

	.innerContainer {
		max-width: 1200px;
		margin: auto;


		.content.center {
			text-align: center;
			max-width: 1200px;
			margin: auto;
		}

		.content {
			padding-top: 120px;
			margin-left: 40px;
			margin-right: 40px;
			padding-bottom: 100px;

			.header {
				margin-bottom: 40px;

				.join {
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: 90%; /* 14.4px */
					letter-spacing: -0.32px;
					text-transform: uppercase;
					margin-bottom: 60px;
				}

				h2 {

					text-align: center;
					font-family: Work Sans;
					font-size: 120px;
					font-style: normal;
					font-weight: 900;
					line-height: 90%; /* 120px */
					text-transform: uppercase;

					@media only screen and (max-width: 1000px) {
						font-size: 64px;
						font-style: normal;
						font-weight: 900;
						line-height: 90%; /* 57.6px */
						text-transform: uppercase;
					}

				}

				.header1 {
				//	filter: blur(8px);
				}

				.italic {
					font-size: 120px;
					font-style: italic;
					font-weight: 300;
					line-height: 100%;
					letter-spacing: -3.6px;
				}

			}

			.subHeader {
				color: #252423;
				font-family: $FONT_SERIF;
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%; /* 22.4px */
				//max-width: 572px;
				max-width: 454px;
				margin: 0 auto;
			}

			.body.center {

				margin: 37px auto;

			}

			.hashtag {
				//font-family: $FONT_SERIF;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 90%; /* 14.4px */
				letter-spacing: -0.32px;
				text-transform: uppercase;
				margin-top: 40px;
				margin-bottom: 168px;
			}

			.graphics {
				display: flex;
				justify-content: center;


				@media only screen and (max-width: 1000px) {
					display:block;
				}

				.graphicsToDownload {
					perspective: 400px;
					perspective-origin: 50% -20%;

					img {
						max-width: 100%;
					}
				}

				.graphic1 {
					margin-right: 40px;



					@media only screen and (max-width: 1000px) {
						margin-right: 0px;
						margin-bottom: 100px;
					}
				}



				.graphic {
					.download {
						margin-top: 40px;
						display: flex;
						justify-content: center;
					}

					.download1 {
						cursor: pointer;
						margin-right: 10px;
					}

					.download2 {
						cursor: pointer;

					}
				}


			}


		}

	}

}
