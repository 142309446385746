.HowItWorksSteps {
	border-top: solid 1px rgba(37, 36, 35, 0.1);
	background-color: $COLOR_BLACK;
	color: $COLOR_SAND;
	z-index: 3;
	position: relative;
	width: 100vw;
	height: auto;

	@media only screen and (max-width: 650px) {
		height: auto;
	}


	.innerContainer {
		max-width: 640px;
		margin: auto;
		text-align: center;

		.header {

			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			font-family: $FONT;
			font-size: 100px;
			font-style: normal;
			font-weight: 900;
			line-height: 90%; /* 90px */
			text-transform: uppercase;
			height: 100vh;

			@media only screen and (max-width: 650px) {
				font-size: 60px;
				padding-left: 40px;
				padding-right: 40px;
				height: 50vh;
			}


		}

		.step.step3 {
			margin-bottom: 0px;
		}

		.step {
		//	height: 100vh;
			height: auto;
			/*display: flex;
			justify-content: center;
			align-items: center;*/
			margin-bottom: 200px;

			@media only screen and (max-width: 650px) {
				height: auto;
			}

			.stepInner {
				max-width: calc(100% - 40px);
				margin-right: 20px;
				margin-left: 20px;

				.cardStepStep {
					font-family: $FONT_SERIF;
					font-size: 12px;
					margin-bottom: 12px;
					//	font-weight: bold;
				}

				.cardStepHeader {
					font-family: $FONT_SERIF;
					font-size: 32px;
					font-style: normal;
					font-weight: 400;
					line-height: 130%; /* 35.2px */
					letter-spacing: -0.96px;

					max-width: 300px;
					margin: 0 auto;
					margin-bottom: 40px;
				}

				.cardStepBody {
					font-family: $FONT_SERIF;
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					line-height: 140%;
					max-width: 320px;
					margin: 0 auto;
				}



			}

			.placeIt {
				margin-left: -250px;

				@media only screen and (max-width: 650px) {
					margin-left: -150px;
				}
			}

			video {
				position: static;
				width: 700px;
				//	margin-left: -250px;margin: auto;

				//	mix-blend-mode: initial;
				@media only screen and (max-width: 650px) {
					width: 400px;
				}
			//	margin: 0 auto;
				margin-left: -30px;

			}

			.video3 {
				margin-left: -40px;
			}
		}

	}

}
